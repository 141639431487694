import { BrowserRouter, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';

import Header from './pages/sections/Header';
import Footer from './pages/sections/Footer';
import { NotFound } from './components/NotFound';
import { AppRoutes } from './AppRoutes';
import { useState } from 'react';
import { ContactUs } from './components/ContactUs';
import { RoutePaths } from './RoutePaths';
import { TopBanner } from './components/TopBanner';
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      {process.env.NODE_ENV === 'development' && (
        <div className="bg-white pl-10 pt-10">
          <p>APENAS DEVS!</p>
          <p>Algo deu errado:</p>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary}>Tente novamente</button>
        </div>
      )}
      <NotFound resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

const browserHistory = createBrowserHistory();

const Page = () => {
  const { pathname } = useLocation();
  const [showContact, setShowContact] = useState(false);

  function toggleContactUs(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setShowContact(!showContact);
  }

  console.log(`pathname`, pathname);

  const focusOnContent = [
    RoutePaths.WORKSHOP,
    RoutePaths.LOAD_TESTING,
    RoutePaths.BOOK_DEMO,
  ].includes(pathname);

  const hideFooter = [RoutePaths.AFFILIATES].includes(pathname);

  return (
    <>
      <TopBanner
        message="New Name, Same Team: zCloud is now Quave Cloud."
        linkTo="https://docs.quave.cloud/blog/2024-11-07-zcloud-is-now-quave-cloud"
        linkLabel="Read more"
        type="info"
        centered
        hidden
      />
      {!focusOnContent && <Header toggleContactUs={toggleContactUs} />}
      <ContactUs
        showContact={showContact}
        setShowContact={setShowContact}
        toggleContactUs={toggleContactUs}
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes toggleContactUs={toggleContactUs} />
      </ErrorBoundary>
      {!focusOnContent && !hideFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <BrowserRouter history={browserHistory}>
      <Page />
    </BrowserRouter>
  );
}

export default App;
