import { RoutePaths } from '../../RoutePaths';
import { getHrefWithSameQueryParams } from '../../utilities';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";

const PRIVATE_COOKIE_NAME = 'zcloud_privacy_policy';

const Footer = () => {
  const [hasPrivacyCookie, setHasPrivacyCookie] = useState(
    Cookies.get(PRIVATE_COOKIE_NAME)
  );

  const handlePrivacyCookie = () => {
    Cookies.set(PRIVATE_COOKIE_NAME, true, { expires: 365 });
    setHasPrivacyCookie(true);
  };
  return (
    <>
      <section data-id="cta" className="bg-neon mt-36 px-4 lg:px-8">
        <div className="container mx-auto flex min-h-[580px] flex-col items-center justify-center gap-8">
          <h2 className="text-h3 md:text-h2 text-center text-neutral-600">
            Deploy your app in minutes
          </h2>
          <h5 className="text-h5 max-w-[800px] text-center text-neutral-300">
            Get started now for free and experience unbeatable value with
            exceptional support.
          </h5>
          <div className="flex flex-col items-center gap-8 sm:flex-row sm:gap-4">
            <Link
              event="website_footer_getStarted_click"
              href={getHrefWithSameQueryParams(RoutePaths.BOOK_DEMO)}
              className="btn-secondary"
            >
              Book a demo
            </Link>
            <p className="text-p">
              <span className="mr-2 text-neutral-200">or</span>
              <Link
                event="website_footer_watchDemoHero_click"
                href="https://www.youtube.com/watch?v=PxIsmmdx8ac"
                target="_blank"
                rel="noreferrer"
                className="text-p font-medium text-neutral-600"
              >
                See it in action
              </Link>
            </p>
          </div>
        </div>
      </section>

      <footer data-id="main-footer" className="px-4 lg:px-8">
        <div className="text-small container mx-auto flex flex-col items-center gap-6 py-10 md:flex-row">
          <div className="flex grow flex-col items-center gap-2 md:items-start md:gap-3">
            <p className="grow">
              © {new Date().getFullYear()} zCloud by <Link href="https://quave.dev">quave</Link>. All
              right reserved.
            </p>
            <ul className="flex items-center gap-2">
              <li>
                <Link
                  event="website_footer_termsAndConditions_click"
                  href={getHrefWithSameQueryParams(
                    RoutePaths.TERMS_AND_CONDITIONS
                  )}
                  target="_blank"
                  rel="noreferrer"
                  className="text-small text-light-700 hover:text-lime-500"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  event="website_footer_privacyPolicy_click"
                  href={getHrefWithSameQueryParams(RoutePaths.PRIVACY_POLICY)}
                  target="_blank"
                  rel="noreferrer"
                  className="text-small text-light-700 hover:text-lime-500"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex grow flex-col items-center gap-6 md:items-end md:gap-3">
            <ul className="flex items-center gap-2">
              <li>
                <Link
                  event="website_footer_statusPage_click"
                  href="https://zcloud.statuspage.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-small text-light-700 hover:text-lime-500"
                >
                  zCloud Status Page
                </Link>
              </li>
            </ul>
            <ul className="flex flex-col items-center gap-6 md:flex-row md:gap-2">
              <li>
                <Link
                  event="website_footer_affiliates_click"
                  href={getHrefWithSameQueryParams(RoutePaths.AFFILIATES)}
                  className="text-small text-light-700 hover:text-lime-500"
                >
                  Become an affiliate
                </Link>
              </li>
              <li className="hidden md:block">|</li>
              <li>
                <Link
                  event="website_footer_youtube_click"
                  href="https://www.youtube.com/@zCloudws_yt"
                  target="_blank"
                  rel="noreferrer"
                  className="text-small text-light-700 flex items-center gap-1 hover:text-lime-500"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                  YouTube
                </Link>
              </li>
              <li className="hidden md:block">|</li>
              <li>
                <Link
                    event="website_footer_twitter_click"
                  to="https://twitter.com/zCloudWS"
                  target="_blank"
                  rel="noreferrer"
                  className="text-small text-light-700 flex items-center gap-1 hover:text-lime-500"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                  Twitter
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <section
        className={`fixed bottom-0 left-0 max-w-[640px] p-4 pt-0 sm:p-8 ${
          hasPrivacyCookie ? 'hidden' : ''
        }`}
      >
        <div className="rounded-8 bg-light-500 flex flex-col items-start gap-3 p-6 shadow-sm shadow-sm md:flex-row md:items-center md:gap-8">
          <p className="text-p text-neutral-500">
            We use cookies and similar technologies to understand how you use
            our site and to help us improve your experience. By continuing, you
            agree to this data collection. For details, check our{' '}
            <Link
              href={getHrefWithSameQueryParams(RoutePaths.PRIVACY_POLICY)}
              className="underline"
            >
              Privacy Policy
            </Link>
            .
          </p>
          <Button
            className="btn-contrast text-small whitespace-nowrap"
            onClick={handlePrivacyCookie}
          >
            I understand
          </Button>
        </div>
      </section>
    </>
  );
};

export default Footer;
